class FixedHeader {

    #element;

    constructor() {
        const header = $('.app-header');
        this.#element = header.clone();
        this.#element.addClass('is-fixed');
        header.after(this.#element);
    }

    update() {
        const html = $('html');
        const body = $('body');
        const viewport = window.innerHeight;
        const scrollTop = html.scrollTop();
        if (scrollTop >= (viewport * 0.4) && !body.hasClass('has-fixed-header')) {
            body.addClass('has-fixed-header');
        } else if (scrollTop < (viewport * 0.4) && body.hasClass('has-fixed-header')) {
            body.removeClass('has-fixed-header');
        }
        window.requestAnimationFrame(() => {
            this.update();
        });
    }
}

export { FixedHeader };
