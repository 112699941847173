import {FixedHeader} from './FixedHeader.js';

class App {

  #fixedHeader;

  constructor() {
    document.addEventListener("turbo:submit-start", ({ target }) => {
      for (const element of target.elements) {
        element.disabled = true;
        if (element.type == 'submit') {
          element.innerHTML = `<i class="fa-solid fa-sync fa-spin"></i> ${element.innerText}`;
        }
      }
    });

    document.addEventListener("turbo:frame-render", ({ detail }) => {
      const meta = document.querySelector('meta[name=csrf-token]');
      meta.content = detail.fetchResponse.response.headers.get('x-csrf-token');
    });

    document.addEventListener("turbo:frame-missing", (event) => {
      event.preventDefault()
      event.detail.visit(window.location.href)
    });

    document.addEventListener("turbo:load", (event) => {
      const body = document.querySelector('body');
      if (body.classList.contains('frontend') || body.classList.contains('status')) {
        this.#fixedHeader = new FixedHeader();
        this.#fixedHeader.update();
      } else {
        this.#fixedHeader = null;
      }
      const anchors = body.querySelectorAll('a[href^="#"]');
      anchors.forEach(anchor => {
        if (anchor.hash) {
          const target = document.querySelector(anchor.hash);
          if (target) {
            anchor.addEventListener('click', e => {
              e.preventDefault();
              window.scroll({
                behavior: 'smooth',
                left: 0,
                top: target.getBoundingClientRect().top +  window.scrollY
              });
            });
          }
        }
      });
      if (window.turnstile || false) {
        if (document.querySelectorAll('.cf-turnstile').length > 0) {
          window.turnstile.render('.cf-turnstile')
        }
      }
    });

    document.addEventListener('alpine:init', () => {
      Alpine.data('utils', () => ({
        goBack() {
          history.back();
        },
        copy() {
          const content = this.$el.dataset.content || null;
          const icon = this.$el.querySelector('i');
          const className = icon.className;
          if (content) {
            try {
              navigator.clipboard.writeText(content);
              icon.className = 'fa-regular fa-fw fa-circle-check';
              setTimeout(() => {
                icon.className = className;
              }, 500)
            } catch (err) {
              //
            }
          }
        },
        unmask() {
          const content = this.$el.dataset.content || null;
          const icon = this.$el.querySelector('i');
          const className = icon.className;
          const target = $(this.$el.dataset.target);
          if (content && target.length) {
            icon.className = 'fa-solid fa-fw fa-unlock text-light';
            target.text(content);
          }
        },
        reveal() {
          const className = this.$el.className;
          const target = $(this.$el.dataset.target);
          if (target.length) {
            switch( target.attr('type') ) {
              case 'password':
                this.$el.className = 'fa-solid fa-unlock text-primary';
                target.attr('type', 'text');
              break;
              case 'text':
                this.$el.className = 'fa-solid fa-lock text-light';
                target.attr('type', 'password');
              break;
            }
          }
        },
        triggerClick() {
          const target = $(this.$el.dataset.target);
          target.trigger('click');
        },
        submitForm() {
          const target = this.$el;
          const form = target.closest('form');
          const button = form.querySelector('button[type=submit]');
          if (target.validity.valid) {
            if (button) {
              const event = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true,
              });
              button.dispatchEvent(event);
            } else {
              form.submit();
            }
          }
        }
      }));

      Alpine.data('form', () => ({
        fields: null,
        button: null,
        init() {
          this.fields = this.$el.querySelectorAll('input, select, textarea');
          this.button = this.$el.querySelector('button[type=submit]');
          const checkable = ['checkbox', 'radio'];
          this.fields.forEach(field => {
            field.checkable = checkable.includes(field.type);
            field.initialValue = field.checkable ? field.checked : field.value;
            field.addEventListener('change', e => {
              this.button.disabled = !this.hasSomethingChanged();
            });
            field.addEventListener('input', e => {
              this.button.disabled = !this.hasSomethingChanged();
            });
          });
          this.button.disabled = true;
        },
        hasSomethingChanged() {
          let ret = false
          this.fields.forEach(field => {
            const value = field.checkable ? field.checked : field.value;
            if (value !== field.initialValue) {
              ret = true;
            }
          });
          return ret;
        }
      }));

      Alpine.data('cart', () => ({
        pickPlan() {
          const plan = this.$el.closest('.option');
          const optionsPlan = this.$refs.optionsPlan.querySelectorAll('.option');
          const optionsFrequency = this.$refs.optionsFrequency.querySelectorAll('.option');
          //
          optionsPlan.forEach(option => {
            option.classList.remove('is-selected');
          });
          plan.classList.add('is-selected');
          //
          optionsFrequency.forEach(option => {
            option.classList.remove('is-selected');
            option.classList.add('is-disabled');
          });
          this.$refs.sku.value = '';
          this.$refs.button.disabled = true;
          //
          fetch(`${window.Settings.Url.home}/xhr/product?plan=${plan.dataset.plan}`)
            .then(response => response.json())
            .then(response => {
              if (response && response.data) {
                optionsFrequency.forEach(option => {
                  const price = response.data.prices[option.dataset.price];
                  const span = option.querySelector('.price');
                  option.dataset.id = price.id;
                  span.innerHTML = `${price.amount} ${price.currency}`;
                  option.classList.remove('is-disabled');
                });
              }
            });
        },
        pickFrequency() {
          const frequency = this.$el.closest('.option');
          const optionsFrequency = this.$refs.optionsFrequency.querySelectorAll('.option');
          //
          optionsFrequency.forEach(option => {
            option.classList.remove('is-selected');
          });
          frequency.classList.add('is-selected');
          //
          this.$refs.sku.value = frequency.dataset.id;
          this.$refs.button.disabled = false;
        }
      }));

      Alpine.data('pulse', () => ({
        init() {
          $(this.$el).pulse({
            formatTime: function(str) {
              return str + ' ms';
            },
            points: 48,
            backgroundColor: '#FFFFFF',
            fillAlpha: 0.65,
            fillColor: [
              '#66BB6A',
              '#FFCA28',
              '#EF5350',
              '#EEEEEE'
            ],
            gutter: 3
          });
          setTimeout(() => {
            $.pulse.api.update( $(this.$el) );
          }, 25);
        },
      }));

      Alpine.data('checkList', () => ({
        init() {
          const options = this.$el.querySelectorAll('input');
          options.forEach((option) => {
            if (!option.disabled && option.checked) {
              const change = new Event('change');
              option.checked = false;
              setTimeout(() => {
                option.checked = true;
                option.dispatchEvent(change);
              }, 0);
            }
          });
        },
        select(event) {
          const anchor = event.target,
            group = anchor.closest('.form-check'),
            input = group.querySelector('input'),
            change = new Event('change');
          if (! input.disabled ) {
            input.checked = true;
            input.dispatchEvent(change);
          }
        }
      }));

      Alpine.data('markdown', () => ({
        init() {
          const textarea = $(this.$el),
            name = textarea.attr('name'),
            form = textarea.closest('form'),
            wrapper = $('<div class="markdown"></div>'),
            source = $('<div class="markdown-source"></div>'),
            preview = $('<div class="markdown-preview format-content major-second"></div>'),
            panel = $('<div class="markdown-panel"></div>'),
            toolbar = $('<div class="markdown-toolbar"><i class="fa-brands fa-markdown mr-2"></i><span>MarkDown</span><a href="#" class="toggle"><strong>Preview</strong></a></div>');

          textarea.wrap(source);
          source.wrap(panel);
          panel.wrap(wrapper);
          panel.append(preview);
          wrapper.append(toolbar);
          textarea.after('<div class="source-editor"></div>')

          ace.config.set('useStrictCSP', true);

          const editor = ace.edit( source.find('.source-editor')[0] );

          editor.setOption('highlightActiveLine', false);
          editor.session.setOption('mode', 'ace/mode/markdown');
          editor.session.setOption('wrap', true);
          editor.renderer.setOption('fontSize', 13);
          editor.renderer.setOption('fontFamily', 'Noto Sans Mono');
          editor.renderer.setOption('theme', 'ace/theme/github');
          editor.renderer.setOption('showGutter', false);
          editor.renderer.setOption('printMargin', false);

          editor.getSession().setValue(textarea.val());

          form.on('submit', () => {
            textarea.val(editor.getSession().getValue());
          });

          editor.getSession().on('change', function(){
            textarea.val(editor.getSession().getValue());
          });

          toolbar.on('click', 'a', (e) => {
            const el = $(e.currentTarget);
            e.preventDefault();
            el.toggleClass('active');
            preview.html( marked.parse( editor.getValue() ) );
            preview.toggleClass('active');
            editor.resize();
          });
        },
      }));

      Alpine.data('related', () => ({
        url: null,
        init() {
          this.url = this.$el.dataset.url;
        },
        update() {
          const name = this.$refs.source.name;
          const value = this.$refs.source.value;
          const target = $(this.$refs.target);
          target.prop('disabled', true);
          target.empty();
          fetch(`${this.url}?${name}=${value}&fields=id,name`)
            .then(response => response.json())
            .then(response => {
              if (response && response.data) {
                target.append(`<option></option>`);
                response.data.forEach((item) => {
                  target.append(`<option value="${item.id}">${item.name}</option>`);
                });
                target.prop('disabled', false);
              }
            });
        }
      }));

      Alpine.data('bookmarks', () => ({
        init() {
          const headings = $(this.$el).find('h1, h2, h3, h4, h5, h6');
          headings.each((index, el) => {
            if ( el.hasAttribute('id') ) {
              const anchor = document.createElement('a');
              anchor.className = 'marker';
              anchor.setAttribute('href', `#${el.id}`);
              anchor.setAttribute('title', 'Copy link');
              anchor.innerHTML = '<i class="fa-solid fa-fw fa-hashtag"></i>';
              anchor.addEventListener('click', (e) => {
                e.preventDefault();
                const content = anchor.href;
                const icon = anchor.querySelector('i');
                const className = icon.className;
                if (content) {
                  try {
                    navigator.clipboard.writeText(content);
                    icon.className = 'fa-regular fa-fw fa-circle-check';
                    setTimeout(() => {
                      icon.className = className;
                    }, 500)
                  } catch (err) {
                    //
                  }
                }
              });
              el.prepend(anchor);
            }
          });
        }
      }));

      Alpine.data('paddle', () => ({
        init() {
          const el = this.$el;
          if (! Paddle.Initialized  ) {
            console.log('💵 Paddle initialize');
            Paddle.Environment.set(window.Settings.Paddle.environment);
            Paddle.Initialize({
              token: window.Settings.Paddle.token,
              checkout: {
                settings: {
                  displayMode: 'inline',
                  frameTarget: 'paddle-checkout',
                  frameInitialHeight: 450,
                  frameStyle: "width: 100%; min-width: 312px; background-color: transparent; border: none;",
                  successUrl: `${window.Settings.Url.home}/payments/thank-you`
                }
              }
            });
          } else {
            console.log('💵 Paddle update');
          }
          const sku = el.dataset.sku || null;
          const quantity = el.dataset.quantity || 1;
          const customer_id = el.dataset.customer || null;
          const items = sku ? [{ priceId: sku, quantity: 1 }] : [];
          const customer = customer_id ? { id: customer_id } : null;
          Paddle.Checkout.open({
            items: items,
            customer: customer
          });
        }
      }));

      Alpine.data('chained', () => ({
        update() {
          const element = this.$el;
          const target = document.querySelector( this.$el.dataset.target );
          const endpoint = element.dataset.endpoint ?? null;
          let params = element.dataset.params ?? null;
          params = params ? '?' + params.replaceAll('{value}', element.value) : '';
          element.disabled = true;
          target.disabled = true;
          fetch(`${window.Settings.Url.home}/${endpoint}${params}`, {
            method: 'GET',
          }).then(response => {
            if (response.headers.get('Content-Type') === 'application/json') {
              response.json().then(json => {
                target.innerHTML = '';
                if (target.dataset.placeholder) {
                  const placeholder = document.createElement('option')
                  placeholder.value = '';
                  placeholder.innerHTML = target.dataset.placeholder;
                  target.append(placeholder);
                }
                json.data.forEach(item => {
                  const option = document.createElement('option');
                  option.value = item.value;
                  option.innerHTML = item.option;
                  target.append(option);
                });
                target.disabled = false;
              });
            }
            element.disabled = false;
          });
        },
      }));

      Alpine.data('lightbox', () => ({
        container: null,
        init() {
          this.container = this.$el;
          const anchors = this.container.querySelectorAll('a');
          let elements = [];
          anchors.forEach(anchor => {
            elements.push({
              href: anchor.href,
              type: 'image',
              title: anchor.dataset.title || null,
              description: anchor.dataset.description || null,
              descPosition: 'right'
            });
          });
          this.container.lightbox = GLightbox({
              elements: elements,
              touchNavigation: true,
              loop: true,
              autoplayVideos: true
          });
        },
        open: function() {
          this.container.lightbox.open();
        }
      }));

      // Alpine.data('ports', () => ({
      //   port: null,
      //   select: null,
      //   init() {
      //     this.select = this.$el.querySelector('select');
      //   },
      //   onChange() {
      //     this.port = this.select.options[ this.select.selectedIndex ].dataset.default;
      //   },
      // }));

      Alpine.data('menu', () => ({
        menu: null,
        init() {
          this.menu = document.querySelector('.app-menu');
        },
        open() {
          this.menu.classList.add('is-open');
        },
        close() {
          this.menu.classList.remove('is-open');
        }
      }));

      Alpine.data('swiper', () => ({
        init() {
          this.$el.swiper = new Swiper(this.$el, {
            loop: true,
            autoplay: {
              delay: 7500,
            },
            spaceBetween: 15,
            pagination: {
              el: this.$el.querySelector('.swiper-pagination'),
            },
            navigation: {
              nextEl: this.$el.querySelector('.swiper-button-next'),
              prevEl: this.$el.querySelector('.swiper-button-prev'),
            },
            scrollbar: {
              el: this.$el.querySelector('.swiper-scrollbar'),
            },
          });
        }
      }));

      Alpine.data('chart', () => ({
        init() {
          const el = this.$el,
            canvas = document.querySelector( el.dataset.canvas ),
            config = JSON.parse( el.value );
          config.options.plugins.tooltip = {
            bodyFont: {
              size: 14
            },
            titleFont: {
              size: 12,
              weight: 'normal'
            },
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || '';
                if (context.parsed.y !== null) {
                    label += new Intl.NumberFormat('en-US').format(context.parsed.y) + ' ms';
                }
                return label;
              }
            }
          };
          el.dataset.chart = new Chart(canvas.getContext('2d'), config);
        }
      }));
    });
  }
}

export { App };
